





































































import { Component, Vue } from 'vue-property-decorator'
import { BButton, BOverlay, BSpinner } from 'bootstrap-vue'
import { RouteNames } from '@/router'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Scalars } from '@/graphql/default/graphql.schema'
import { cart } from '@/store/cart/cartModule'
import { customer } from '@/store/customer'
import { operator } from '@/store/operator'
import terminalModule from '@/store/terminal'
const QuantityField = () => import('@/components/QuantityField.vue')
const ProductCardMini = () => import('@/components/ProductCardMini.vue')

@Component({
  components: { ProductCardMini, QuantityField, BButton, BSpinner, BOverlay }
})
export default class MiniBasket extends Vue {
  readonly RouteNames = RouteNames
  loading=0

  /**
   * Цена без модификаторов
   */
  get commonPrice():number|null {
    return cart.commonPrice
  }

  /**
   * Расчётная цена корзины
   */
  get totalPrice(): Maybe<number> {
    return cart.totalPrice
  }

  /**
   * Определяет будет ли клиент тратить бонусные баллы
   */
  get spendScores(): boolean {
    return customer.spendScores
  }

  get thereIsSale() {
    return this.commonPrice !== this.totalPrice || this.spendScores
  }

  get products() {
    return cart.products
  }

  get isShowAddToBasket() {
    return !terminalModule.getters.terminal?.isDemoMode
  }

  get isServiceMode() {
    return operator.operatorId
  }

  changeQuality(quality:number, productId:Scalars['ID']) {
    cart.changeQuality({ productId, quality })
  }
}
